<template>
  <v-container>
    <title-header-action
      btnLabel="Continuar registro de lote"
      :title="title"
      :hasActionPermission="userCanContinueBatchRegistration"
      :btnAction="goToRegisterScreening"
    />
    <v-card class="table-page mt-5">
      <v-layout v-if="screening" class="inherit-height" d-flex flex-column>
        <v-container class="pa-5">
          <v-row>
            <v-col cols="12" md="3" lg="2">
              <img
                class="mb-2 logo"
                :src="
                  require('@/assets/logotipo-programando-futuro-horizontal.svg')
                "
                alt="Logo Programando o Futuro"
              />
            </v-col>
            <v-col cols="12" md="9" lg="4">
              <v-card :elevation="1" :min-height="120">
                <v-row>
                  <v-col cols="12" sm="6" align-self="start">
                    <p class="black-2--text ma-0 ml-5">
                      Doador/Parceiro:
                    </p>
                    <p class="black-3--text ma-0 ml-5">
                      {{ screening.sourceName }}
                    </p>
                  </v-col>
                  <v-col cols="12" sm="6" align-self="start">
                    <p class="black-2--text ma-0 ml-5">
                      Cidade - UF:
                    </p>
                    <p class="black-3--text ma-0 ml-5">
                      {{ screening.location }}
                    </p>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" sm="11" lg="5">
              <v-card :elevation="1" :min-height="120">
                <v-row>
                  <v-col cols="12" sm="6" md="3" align-self="start">
                    <p class="black-2--text ma-0 ml-5">
                      Data de recebimento:
                    </p>
                    <p class="black-3--text ma-0 ml-5">
                      {{ screening.createdAt }}
                    </p>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" align-self="start">
                    <p class="black-2--text ma-0 ml-5">
                      Pesso total atual:
                    </p>
                    <p class="black-3--text ma-0 ml-5">
                      {{ screening.weightTotal }}
                    </p>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" align-self="start">
                    <p class="black-2--text ma-0 ml-5">
                      Status da coleta:
                    </p>
                    <p class="black-3--text ma-0 ml-5">
                      {{ screening.situation }}
                    </p>
                  </v-col>
                  <v-col cols="12" sm="6" md="3" align-self="start">
                    <p class="black-2--text ma-0 ml-5">
                      Status do lote:
                    </p>
                    <p class="black-3--text ma-0 ml-5">
                      {{ screening.statusDescription }}
                    </p>
                  </v-col>
                </v-row>
              </v-card>
            </v-col>
            <v-col cols="12" sm="1" lg="1" align-self="center">
              <div class="d-flex justify-center">
                <btn-report
                  @pdfReport="handlePdfReport()"
                  @excelReport="handleExcelReport()"
                  @csvReport="handleCsvReport()"
                />
              </div>
            </v-col>
          </v-row>
        </v-container>

        <v-layout
          v-if="batchItems.length"
          d-flex
          flex-column
          justify-space-between
          class="fill-height"
        >
          <v-data-table
            v-model="selectedBatchItems"
            :headers="headers"
            :items="batchItems"
            :server-items-length="tableInfo.totalItems"
            :show-select="userCanContinueBatchRegistration"
            hide-default-footer
          >
            <template v-slot:[`item.id`]="{ item }">
              <menu-item
                v-if="userCanContinueBatchRegistration"
                :fnConfirmDelete="handleConfirmDelete"
                :id="item.id"
              />
            </template>
          </v-data-table>
          <pagination
            :currentPage="tableInfo.currentPage"
            :totalPages="tableInfo.totalPages"
            :itemsPerPage="tableInfo.itemsPerPage"
            @currentPageChanged="handleCurrentPageChange($event)"
            @itemsPerPageChanged="handleItemsPerPageChange($event)"
          />
        </v-layout>
        <div v-else class="d-flex flex-column justify-center inherit-height">
          <batch-not-found title="Nenhum item do lote em lote" />
        </div>
      </v-layout>
    </v-card>
    <v-row v-if="userCanContinueBatchRegistration" justify="end">
      <v-col cols="12" sm="5" md="4" lg="3">
        <v-btn
          block
          color="secondary-color-1"
          class="next-step-button white--text"
          :disabled="!batchItems.length"
          @click="openConfirmDestinyDialog"
        >
          Encaminhar para Área Designada
        </v-btn>
      </v-col>
      <v-col cols="12" sm="5" md="4" lg="3">
        <v-btn
          block
          outlined
          color="secondary-color-1"
          class="next-step-button"
          @click="openConcludeBatchDialog"
        >
          Concluir Lote
        </v-btn>
      </v-col>
    </v-row>
    <confirm-destiny-dialog
      :state="confirmDestinyDialogState"
      :fnConfirm="handleConfirmDestiny"
    />
    <confirm-conclude-batch-dialog
      :state="concludeBatchDialogState"
      :fnConfirm="handleConcludeBatch"
      :batchNumber="screening.batchNumber"
    />
  </v-container>
</template>

<script>
  import TableHandler from "@/modules/core/mixins/tableHandler";
  import BatchNotFound from "@/modules/stock/batch/views/components/BatchNotFound";
  import BtnReport from "@/modules/core/views/components/BtnReport";
  import ConfirmConcludeBatchDialog from "@/modules/stock/batch/views/components/ConfirmConcludeBatchDialog";
  import ConfirmDestinyDialog from "@/modules/stock/batch/views/components/ConfirmDestinyDialog";
  import MasksMixin from "@/modules/core/mixins/masksMixin";
  import Pagination from "@/modules/core/views/components/Pagination";
  import TitleHeaderAction from "@/modules/core/views/components/TitleHeaderAction";
  import MenuItem from "@/modules/stock/batch/views/components/screening-items/MenuItem";
  import { requestSituation } from "@/modules/agenda/enums/request-situation.enum";
  import { screeningStatus } from "../../enums/screeningTypes.enum";

  import { mapGetters, mapActions } from "vuex";

  export default {
    name: "ViewScreening",

    components: {
      TitleHeaderAction,
      Pagination,
      ConfirmDestinyDialog,
      ConfirmConcludeBatchDialog,
      BatchNotFound,
      MenuItem,
      BtnReport
    },

    mixins: [MasksMixin, TableHandler],

    data: () => ({
      baseHeaders: [
        {
          text: "Data Registro",
          align: "start",
          value: "createdAt"
        },
        { text: "Equipamento/Registro", value: "typeDescription" },
        { text: "Marca/Modelo", value: "brandAndModelDescription" },
        { text: "Pesagem Total", value: "weightTotal" },
        { text: "Quantidade", value: "amount" },
        { text: "Área Destinada", value: "destiny" }
      ],
      screeningStatus: screeningStatus,
      screening: {
        id: undefined,
        batchNumber: "",
        status: undefined,
        situation: undefined,
        collectionRequest: undefined,
        weightTotal: undefined
      },
      confirmDestinyDialogState: {
        dialog: false
      },
      concludeBatchDialogState: {
        dialog: false
      },
      batchItems: [],
      selectedBatchItems: []
    }),

    mounted() {
      this.updateData();
    },

    computed: {
      ...mapGetters({
        userRoleId: "user/getUserRoleId",
        routes: "getRoutes"
      }),

      title() {
        return this.screening?.batchNumber
          ? `Registro Lote ${this.screening.batchNumber}`
          : "Registro Lote";
      },
      hasSelectedBatchItems() {
        return this.selectedBatchItems?.length;
      },
      userCanManageScreening() {
        return this.routes?.some(
          route =>
            route.name === "new-screening" &&
            route?.meta?.permissions.includes(this.userRoleId)
        );
      },
      userCanContinueBatchRegistration() {
        return (
          this.userCanManageScreening &&
          this.screening.status === this.screeningStatus.IN_SCREENING
        );
      },
      headers() {
        const baseHeaders = [...this.baseHeaders];

        if (this.userCanContinueBatchRegistration) {
          baseHeaders.push({
            value: "id",
            sortable: false
          });
        }

        return baseHeaders;
      }
    },

    methods: {
      ...mapActions(["toggleSnackbar"]),
      ...mapActions("stock/batch", [
        "getBatch",
        "getBatchItems",
        "sendBatchItemsToDestiny",
        "concludeBatch",
        "deleteBatchItem",
        "keepCreate",
        "downloadBatchReportPdf",
        "downloadBatchReportExcel",
        "downloadBatchReportCsv"
      ]),

      getBatchId() {
        return parseInt(this.$route.params.id, 10);
      },

      goToRegisterScreening() {
        this.keepCreate({
          batch: {
            id: this.screening.id,
            batchNumber: this.screening.batchNumber
          }
        });
        this.$router.push({ name: "new-screening" });
      },

      async fetchBatch() {
        try {
          const batchId = this.getBatchId();
          const { data } = await this.getBatch({
            batchId
          });

          this.screening = this.formatBatch(data);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      formatBatch(batch) {
        return {
          id: batch.id,
          batchNumber: batch.batchNumber,
          status: batch.status?.id,
          statusDescription: batch.status?.description,
          situation: batch.collectionRequest?.situation?.id
            ? this.getCollectionRequestSituationById(
                batch.collectionRequest.situation.id
              )
            : "Não informado",
          weightTotal: this.safeParseWeight(batch.weightTotal),
          sourceName: batch.collectionRequest.sourceName ?? "Não informado",
          location: batch.collectionRequest.crc?.location ?? "Não informado",
          createdAt: this.parseDate(batch.createdAt, "dd/MM/yyyy")
        };
      },

      getCollectionRequestSituationById(collectionRequestSituationId) {
        const situations = Object.values(requestSituation);
        const situation = situations.find(
          item => item.id === collectionRequestSituationId
        );

        return situation.descricao;
      },

      async fetchBatchItems() {
        try {
          const { page, limit } = this.getPayloadData();
          const batchId = this.getBatchId();

          const { data, meta } = await this.getBatchItems({
            batchId,
            page,
            limit
          });

          this.setTableInfo(meta);
          this.batchItems = this.formatBatchItems(data);
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      formatBatchItems(batchItems) {
        return batchItems.map(batchItem => {
          return {
            id: batchItem.id,
            createdAt: this.parseDate(batchItem.createdAt, "dd/MM/yyyy"),
            typeDescription: batchItem.typeDescription,
            brandAndModelDescription: batchItem.brandAndModelDescription,
            weightTotal: this.safeParseWeight(batchItem.weightTotal),
            amount: batchItem.amount,
            destiny: batchItem.destiny?.description ?? "não informado"
          };
        });
      },

      updateData() {
        this.fetchBatch();
        this.fetchBatchItems();
      },

      openConfirmDestinyDialog() {
        this.confirmDestinyDialogState.dialog = true;
      },

      clearSelectedBatchItems() {
        this.selectedBatchItems = [];
      },

      mountPayloadSendDestiny() {
        return {
          batchItems: this.selectedBatchItems.map(
            selectedBatch => selectedBatch.id
          )
        };
      },

      async handleConfirmDestiny() {
        try {
          const payload = this.mountPayloadSendDestiny();
          await this.sendBatchItemsToDestiny(payload);

          this.clearSelectedBatchItems();
          this.updateData();

          this.toggleSnackbar({
            text: "Itens encaminhados para a área designada",
            type: "success"
          });
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      async handleConfirmDelete(screeningItemId) {
        try {
          await this.deleteBatchItem(screeningItemId);

          this.toggleSnackbar({
            text: "Registro excluído com sucesso",
            type: "success"
          });

          this.updateData();
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      openConcludeBatchDialog() {
        this.concludeBatchDialogState.dialog = true;
      },

      async handleConcludeBatch() {
        try {
          const batchId = this.getBatchId();
          await this.concludeBatch(batchId);

          this.toggleSnackbar({
            text: "Triagem do lote concluída com sucesso",
            type: "success"
          });

          this.$router.push({ name: "screening" });
        } catch (error) {
          this.toggleSnackbar({
            text: error?.data?.message ?? "Ocorreu um erro. Tente novamente",
            type: "error"
          });
        }
      },

      handlePdfReport() {
        this.downloadBatchReportPdf(this.getBatchId());
      },

      handleExcelReport() {
        this.downloadBatchReportExcel(this.getBatchId());
      },

      handleCsvReport() {
        this.downloadBatchReportCsv(this.getBatchId());
      }
    }
  };
</script>

<style lang="scss" scoped>
  .logo {
    max-width: 220px;
    width: 100%;
  }
</style>
